"use client";
import React, { useLayoutEffect, useState } from "react";
import CollectionView from "./CollectionComponentView";
import { useDispatch, useSelector } from "react-redux";
import { removeCollection, removeCollectionById } from "@/src/redux/actions";
import { manageCollections } from "@/src/redux/reducers/slices";

const CollectionContainer = ({ token }) => {
  const [deleteCollection, setDeleteCollection] = useState({
    isModelOpen: false,
    isConfirm: false,
    name: "",
    inputValue: "",
  });
  const [changeClass, setChangeClass] = useState(false);
  const [hydrated, setHydrated] = useState(false);
  const [collectionDrawer, setCollectionDrawer] = useState({
    isLoading: false,
    data: [],
    name: "",
    error: "",
    openCollection: "",
    isOpen: false,
  });
  const { manageCollection } = useSelector((state) => state.collection);
  const dispatch = useDispatch();

  const changeClassOnScroll = () => {
    const scrollValue = document.documentElement.scrollTop;
    if (scrollValue > 80) {
      setChangeClass(true);
    } else {
      setChangeClass(false);
    }
  };
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", changeClassOnScroll);
  }
  const handleRemove = (id) => {
    dispatch(removeCollectionById(id));
  };
  const onOpenCollectionDrawer = (collectionName) => {
    setCollectionDrawer((prev) => ({
      ...prev,
      openCollection: collectionName,
      isOpen: true,
      name: collectionName,
      data: manageCollection[collectionName],
    }));
  };
  const onCloseCollectionDrawer = () => {
    setCollectionDrawer({
      data: [],
      error: "",
      openCollection: "",
      isOpen: false,
      name: "",
    });
  };

  const handleRemoveCollection = () => {
    setDeleteCollection((prev) => ({ ...prev, isModelOpen: true }));
  };
  const handleCancelCollection = () => {
    setDeleteCollection((prev) => ({
      ...prev,
      isModelOpen: false,
      inputValue: "",
    }));
  };
  const handleSaveCollection = async (collectionName) => {
    setCollectionDrawer({
      error: "",
      openCollection: "",
      isOpen: false,
      name: "",
    });
    dispatch(removeCollection(collectionName));
    const a = Object?.keys(manageCollection)
      ?.filter((ele) => ele !== collectionName)
      .reduce((acc, ele) => ({ ...acc, [ele]: manageCollection[ele] }), {});
    await dispatch(manageCollections(a));
    setDeleteCollection((prev) => ({
      ...prev,
      isModelOpen: false,
      inputValue: "",
    }));
  };
  const handleDeleteCollectionInputChange = (e) => {
    const { value } = e.target;

    const name = collectionDrawer?.name;
    if (name === value) {
      setDeleteCollection((prev) => ({
        ...prev,
        isConfirm: true,
        inputValue: value,
      }));
    } else {
      setDeleteCollection((prev) => ({
        ...prev,
        isConfirm: false,
        inputValue: value,
      }));
    }
  };
  useLayoutEffect(() => {
    setCollectionDrawer((prev) => ({
      ...prev,
      data: manageCollection[collectionDrawer?.openCollection],
    }));
  }, [manageCollection]);

  useLayoutEffect(() => {
    setHydrated(true);
  }, []);

  if (!hydrated) return null;

  return (
    <CollectionView
      {...{
        token,
        deleteCollection,
        collectionDrawer,
        onOpenCollectionDrawer,
        onCloseCollectionDrawer,
        handleRemove,
        collectionDataByCollectionName: manageCollection,
        changeClass,
        handleRemoveCollection,
        handleSaveCollection,
        handleCancelCollection,
        handleDeleteCollectionInputChange,
      }}
    />
  );
};

export default CollectionContainer;
