"use client";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import FilterDropDownView from "./FilterDropDownView";
import "../../../../styles/filterNavbar/filterNavbar.scss";
import { COUNTRY_LIST_PHONE_CODE } from "@/src/const/country";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, nearestColors } from "@/src/utils";
import { useNav } from "../../CommonComponents/context/NavbarProvider";
import { getWebsiteScreenShot } from "@/src/redux/actions";
import {
  isAllWebsiteEndPage,
  isLoadingWebsite,
  websiteSearchData,
  // websites,
} from "@/src/redux/reducers/slices";
import { TWELVE_COUNT } from "@/src/const/primitive";

const FilterDropDownContainer = () => {
  const [dropDownFilterObj, setDropDownFilterObj] = useState({
    popular: [],
    typography: [],
    country: [],
  });
  const getRegionNames = new Intl.DisplayNames(["en"], { type: "region" });

  const { categoryData, stylesData, fontFamilyData } = useSelector(
    (state) => state.categoryAndStyles
  );
  const [isResponsive, setIsResponsive] = useState({
    screenSize: "",
    isOpenMenu: false,
  });
  const [dropdownSearchValue, setDropdownSearchValue] = useState("");

  const {
    filterRef,
    open,
    isDrawerOpen,
    setOpen,
    setIsDrawerOpen,
    setIsScreenShotOpen,
    setIsSearch,
    filterValue,
    setFilterValue,
    filterValueJson,
    setFilterValueJson,
    // isSearch,
  } = useNav();

  useLayoutEffect(() => {
    setIsResponsive({
      ...isResponsive,
      screenSize: typeof window !== "undefined" ? window.innerWidth : "",
    });
  }, []);

  const [defaultClose, setDefaultClose] = useState(false);
  const [drawerData, setDrawerData] = useState();
  const [filterDropDownName, setFilterDropDownName] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);
  const responsiveMenuWrap = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleScreenShotOpenDrawer = () => {
    setFilterDropDownName("webSnapshot");
    setIsScreenShotOpen(true);
    setOpen(false);
  };
  const handleGetFilterWebsiteData = async (
    sendFilterData,
    isLastPageOrderBY,
    EmptySearchData
  ) => {
    if (isLastPageOrderBY === false) {
      const payloadData = {
        page: 1,
        limit: TWELVE_COUNT,
        ...sendFilterData,
      };
      const res = await getWebsiteScreenShot(payloadData);
      if (!isEmpty(res?.data)) {
        dispatch(isAllWebsiteEndPage(res?.isEnd));
        let SearchData = [...EmptySearchData, ...res?.data];
        await dispatch(websiteSearchData(SearchData));
      }
      await dispatch(isLoadingWebsite(false));
    }
  };
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const handleFilterChange = async (e, title) => {
    let filterData;
    let newTitle = title?.toLowerCase();
    if (title === "Color") {
      setIsSearch(true);
      if (typeof window !== "undefined") {
        window?.scrollTo({
          top: filterRef.current.offsetTop,
          behavior: "smooth",
        });
      }
      let searchRec = [];
      const colors = e;
      filterData = nearestColors(colors);
      let sendFilterData = {
        ...filterValueJson,
        [newTitle]: filterData,
      };
      await handleGetFilterWebsiteData(sendFilterData, false, searchRec);
    } else {
      const { value } = e.target;
      if (isEmpty(value)) {
        setIsSearch(false);
      } else {
        setIsSearch(true);
      }
      setCategorySearch({
        ...categorySearch,
        searchKey: value,
      });
    }
  };
  const handleFilterWebsiteData = async (title, filterData) => {
    let newTitle = title?.toLowerCase();
    setIsSearch(true);
    dispatch(isLoadingWebsite(true));
    let EmptySearchData = [];
    if (typeof window !== "undefined") {
      window?.scrollTo({
        top: filterRef.current.offsetTop,
        behavior: "smooth",
      });
    }
    let isLastPageOrderBY = false;
    let sendFilterData = {
      ...filterValueJson,
      [newTitle]: filterData,
    };
    isLastPageOrderBY = false;
    setFilterValueJson(sendFilterData);
    setFilterValue(filterData);
    setOpen(false);
    setIsDrawerOpen({
      popular: false,
      typography: false,
      country: false,
    });
    await handleGetFilterWebsiteData(
      sendFilterData,
      isLastPageOrderBY,
      EmptySearchData
    );
  };
  useEffect(() => {
    setOpen(false);
    setDefaultClose(false);
  }, []);
  const showDrawer = (name) => {
    const drawerOpen = {
      [name]: !isDrawerOpen[name],
    };
    setIsDrawerOpen(drawerOpen);
    setOpen(drawerOpen[name]);
    setDefaultClose(true);
    setDropdownSearchValue("");
    if (name === "popular".toLowerCase()) {
      setDrawerData(dropDownFilterObj?.popular);
    } else if (name === "typography".toLowerCase()) {
      setDrawerData(dropDownFilterObj?.typography);
    } else {
      setDrawerData(dropDownFilterObj?.country);
    }
  };
  const handleDropDownSearchValue = (e) => {
    const { value } = e?.target;
    setDropdownSearchValue(value);
    const searchingIn = Object?.keys(isDrawerOpen)[0];
    const title = dropDownFilterObj[searchingIn]?.map((obj) => obj?.title);
    const data = dropDownFilterObj[searchingIn]?.map((obj) => obj?.data);
    const filteredData = data?.map((wordObj) =>
      wordObj?.filter((ele) => {
        return title?.includes("Country")
          ? getRegionNames
              .of(ele?.name)
              ?.toLowerCase()
              .startsWith(value.toLowerCase())
          : ele?.name?.toLowerCase().startsWith(value.toLowerCase());
      })
    );
    let updatedArr = [];
    for (let i = 0; i < filteredData?.length; i++) {
      let value;
      let obj = {};
      if (isEmpty(filteredData[i])) {
        value = data[i];
      } else {
        value = filteredData[i];
      }
      obj.data = value;
      obj.title = title[i];
      updatedArr.push(obj);
    }
    setDrawerData(updatedArr);
  };
  useEffect(() => {
    if (dropdownSearchValue === "") {
      setDropDownFilterObj({
        popular: [
          {
            title: "Category",
            data: categoryData?.map((obj) => ({
              name: obj?.categoryName,
              id: obj?.categoryId,
            })),
          },
          {
            title: "Style",
            data: stylesData?.map((obj) => ({
              name: obj?.styleName,
            })),
          },
        ],
        typography: [
          {
            title: "Font",
            data: fontFamilyData?.map((font) => ({
              name: font?.fontName,
            })),
          },
          {
            title: "Color",
            data: [],
          },
        ],
        country: [
          {
            title: "Country",
            data: COUNTRY_LIST_PHONE_CODE?.map((obj) => ({
              name: obj?.name,
            })),
          },
        ],
      });
    }
  }, [
    categoryData,
    stylesData,
    fontFamilyData,
    dropdownSearchValue,
    drawerData,
    filterValue,
  ]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setOpen(false);
        setIsDrawerOpen({
          popular: false,
          typography: false,
          country: false,
        });
        // setIsResponsive({
        //   ...isResponsive,
        //   isOpenMenu: false,
        // });
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [open, componentRef, isResponsive?.isOpenMenu]);
  const handleCloseFilterData = async (title) => {
    let newTitle = title?.toLowerCase();
    dispatch(isLoadingWebsite(true));
    const filterJsonValue = {
      ...filterValueJson,
      [newTitle]: undefined,
    };
    const filteredValue = Object.values(filterJsonValue).filter((ele) =>
      ele?.trim()
    );
    setFilterValueJson(filterJsonValue);
    await handleGetFilterWebsiteData(filterJsonValue, false, []);
    if (filteredValue?.length > 0) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    setIsDrawerOpen({
      popular: false,
      typography: false,
      country: false,
    });
    setOpen(false);
    setFilterValue("");
  };
  const handleClickCollapse = () => {
    setIsResponsive({
      ...isResponsive,
      isOpenMenu: !isResponsive?.isOpenMenu,
    });
    setOpen(false);
    setDefaultClose(false);
  };
  const handleClickDisplayData = (title, name) => {
    setFilterDropDownName(name);
    const a = dropDownFilterObj[title].map((obj) => {
      if (name === obj?.title) {
        return {
          title: obj?.title,
          data: obj?.data,
        };
      } else {
        return {};
      }
    });
    setDrawerData(a);
    const drawerOpen = {
      [title]: !isDrawerOpen[title],
    };
    setIsDrawerOpen(drawerOpen);
    setOpen(drawerOpen[title]);
    setDefaultClose(true);
    setDropDownFilterObj({
      [title]: a,
    });
  };
  const handleBackData = () => {
    setOpen(false);
    setIsDrawerOpen({
      popular: false,
      typography: false,
      country: false,
    });
  };

  return (
    <React.Fragment>
      <FilterDropDownView
        {...{
          filterDropDownName,
          filterValueJson,
          isResponsive,
          filterValue,
          showDrawer,
          open,
          isVisible,
          componentRef,
          responsiveMenuWrap,
          drawerData,
          isDrawerOpen,
          defaultClose,
          handleDropDownSearchValue,
          handleFilterData: handleFilterWebsiteData,
          handleFilterChange,
          dropdownSearchValue,
          handleCloseFilterData,
          handleClickCollapse,
          getRegionNames,
          handleScreenShotOpenDrawer,
          handleClickDisplayData,
          handleBackData,
          handleScrollToTop,
        }}
      />
    </React.Fragment>
  );
};

export default FilterDropDownContainer;
