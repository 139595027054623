import React from "react";
import FilterResponsiveNavMenu from "./FilterResponsiveNavMenu";
import FilterNavMenu from "./FilterNavMenu";
import { upArrow } from "@/public/assets";
import dynamic from "next/dynamic";
const Lottie = dynamic(() => import("react-lottie-player"));

const FilterDropDownView = (props) => {
  const {
    filterDropDownName,
    filterValueJson,
    isResponsive,
    filterValue,
    showDrawer,
    open,
    isVisible,
    componentRef,
    responsiveMenuWrap,
    drawerData,
    onClose,
    isDrawerOpen,
    defaultClose,
    handleFilterData,
    handleDropDownSearchValue,
    handleFilterChange,
    dropdownSearchValue,
    handleCloseFilterData,
    handleClickCollapse,
    getRegionNames,
    handleScreenShotOpenDrawer,
    handleClickDisplayData,
    handleBackData,
    handleScrollToTop,
  } = props;

  return (
    <div className={`${"filter_dropdown_wrap"}`}>
      {isResponsive?.screenSize < 993 ? (
        <FilterResponsiveNavMenu
          {...{
            filterDropDownName,
            filterValueJson,
            handleClickCollapse,
            componentRef,
            responsiveMenuWrap,
            showDrawer,
            isResponsive,
            filterValue,
            onClose,
            open,
            data: drawerData,
            defaultClose,
            handleFilterData,
            handleDropDownSearchValue,
            handleFilterChange,
            dropdownSearchValue,
            handleCloseFilterData,
            getRegionNames,
            handleScreenShotOpenDrawer,
            handleClickDisplayData,
            handleBackData,
          }}
        />
      ) : (
        <FilterNavMenu
          {...{
            filterValueJson,
            filterValue,
            onClose,
            open,
            drawerData,
            componentRef,
            defaultClose,
            handleFilterData,
            handleDropDownSearchValue,
            handleFilterChange,
            dropdownSearchValue,
            handleCloseFilterData,
            getRegionNames,
            isDrawerOpen,
            showDrawer,
            handleScrollToTop,
          }}
        />
      )}
      {isVisible && (
        <div onClick={handleScrollToTop} className={"arrow_main"}>
          <Lottie loop animationData={upArrow} play className={"arrow_image"} />
        </div>
      )}
    </div>
  );
};

export default FilterDropDownView;
