"use client";
import React, { useEffect, useLayoutEffect, useState } from "react";
import AllWebsiteView from "./AllWebsiteView";
import { useDispatch, useSelector } from "react-redux";
import {
  PortfolioProfileData,
  isAllWebsiteEndPage,
  isLoadingWebsite,
  websiteSearchData,
  websites,
} from "@/src/redux/reducers/slices";
import { useNav } from "@/src/components/CommonComponents/context/NavbarProvider";
import { getWebsiteScreenShot } from "@/src/redux/actions";
import { isEmpty } from "@/src/utils";
import { TWELVE_COUNT } from "@/src/const/primitive";

const AllWebsiteContainer = (props) => {
  const { token } = props;
  const {
    websiteData,
    searchRec,
    isWebsiteAllDataLastPage,
    isLoadingWebsiteState,
  } = useSelector((state) => state.website);
  const [orderByFilter, setOrderByFilter] = useState("Newest");
  const [numberOdWebsites, setNumberOfWebsites] = useState();
  const {
    isSearch,
    filterValueJson,
    filterRef,
    setFilterValueJson,
    setIsSearch,
    setIsDrawerOpen,
    setFilterValue,
  } = useNav();
  const dispatch = useDispatch();

  const handleGetFilterWebsiteData = async (
    isLastPageOrderBY,
    EmptySearchData,
    filterData,
    sendFilterData
  ) => {
    if (isLastPageOrderBY === false) {
      dispatch(isLoadingWebsite(true));
      const payloadData = {
        page: 1,
        limit: TWELVE_COUNT,
        ...sendFilterData,
        orderBy: filterData,
      };
      const res = await getWebsiteScreenShot(payloadData);

      if (!isEmpty(res?.data)) {
        dispatch(isAllWebsiteEndPage(res?.isEnd));
        let SearchData = [...EmptySearchData, ...res?.data];
        await dispatch(websiteSearchData(SearchData));
      } else {
        await dispatch(websiteSearchData([]));
      }
      await dispatch(isLoadingWebsite(false));
    }
  };
  const [isDropDownLoad, setIsDropDownLoad] = useState(true);

  useLayoutEffect(() => {
    dispatch(PortfolioProfileData({}));
  }, []);

  const handleFilterWebsiteData = async (title, filterData) => {
    let newTitle = title?.toLowerCase();
    setIsSearch(true);

    let EmptySearchData = [];
    if (typeof window !== "undefined") {
      window?.scrollTo({
        top: filterRef.current.offsetTop,
        behavior: "smooth",
      });
    }
    let isLastPageOrderBY = isWebsiteAllDataLastPage;
    let sendFilterData = {
      ...filterValueJson,
      [newTitle]: filterData,
    };
    if (title === "orderBy") {
      EmptySearchData = [];
      dispatch(websiteSearchData([]));
      if (isEmpty(filterData)) {
        isLastPageOrderBY = false;
        setOrderByFilter("Newest");
        setIsSearch(false);
        sendFilterData = { [title]: "Newest" };
      } else {
        isLastPageOrderBY = false;
        setOrderByFilter(filterData);
      }
    }
    isLastPageOrderBY = false;
    setFilterValueJson(sendFilterData);
    setFilterValue(filterData);
    setIsDrawerOpen({
      popular: false,
      typography: false,
      country: false,
    });
    await handleGetFilterWebsiteData(
      isLastPageOrderBY,
      EmptySearchData,
      filterData,
      sendFilterData
    );
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setIsDropDownLoad(true);
    const timer = setTimeout(() => {
      setIsDropDownLoad(false);
    }, 900);
    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (isEmpty(websiteData)) {
      dispatch(isLoadingWebsite(true));
      const allWebsiteData = async () => {
        const payload = {
          page: 1,
          limit: 12,
        };
        const allWebsites = await getWebsiteScreenShot(payload);
        await dispatch(websites(allWebsites?.data));
        await dispatch(isAllWebsiteEndPage(allWebsites?.isEnd));
        await dispatch(isLoadingWebsite(false));
      };
      allWebsiteData();
    }
  }, []);
  return (
    <AllWebsiteView
      {...{
        allWebsites: isSearch ? searchRec : websiteData,
        token: token?.value,
        isSearch,
        filterValueJson,
        filterRef,
        orderByFilter,
        handleFilterWebsiteData,
        isLoadingWebsiteState,
        numberOdWebsites,
        setNumberOfWebsites,
        isWebsiteAllDataLastPage,
        isDropDownLoad,
      }}
    />
  );
};

export default AllWebsiteContainer;
