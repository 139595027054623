import React from "react";
import "../../../../../styles/collection/collection.scss";
import CollectionByName from "./CollectionByName/CollectionByName";
import {
  BackButtonComponent,
  ButtonComponent,
  InputComponent,
  NoDataFound,
} from "@/src/components/CommonComponents";
import { Col, Drawer, Modal, Row, Skeleton } from "antd";
import { isEmpty } from "@/src/utils";
import { imageIcon } from "@/public/assets";
import Image from "next/image";
import WebsiteCardContainer from "@/src/components/CommonComponents/WebsiteCards/WebsiteCardContainer";

const CollectionView = (props) => {
  const {
    deleteCollection,
    token,
    isLoadingCollection,
    collectionDataByCollectionName,
    handleRemove,
    changeClass,
    onOpenCollectionDrawer,
    collectionDrawer,
    onCloseCollectionDrawer,
    handleRemoveCollection,
    handleSaveCollection,
    handleCancelCollection,
    handleDeleteCollectionInputChange,
  } = props;
  return (
    <div>
      <div
        className={`${"collection-main"} ${
          changeClass
            ? "collection-on-scroll-main"
            : "collection-background-main"
        } ${
          !isLoadingCollection &&
          Object?.keys(collectionDataByCollectionName)?.length <= 4
            ? "height-main"
            : ""
        }`}
      >
        <BackButtonComponent url="/" classNames={"collection-back-btn"} />
        <div>
          <h2 className={"website-collection-title"}>
            your featured collections
          </h2>
        </div>
        <Row gutter={[30, 30]}>
          {!isEmpty(collectionDataByCollectionName) ? (
            Object?.keys(collectionDataByCollectionName)?.length > 0 &&
            Object?.keys(collectionDataByCollectionName)?.map(
              (collectionName) => {
                const collectionSliceData = collectionDataByCollectionName[
                  collectionName
                ]?.slice(0, 4);
                return (
                  <Col
                    key={collectionName}
                    span={24}
                    xxl={6}
                    xl={6}
                    lg={8}
                    md={8}
                    sm={12}
                    className={"collection"}
                  >
                    <div
                      onClick={() => onOpenCollectionDrawer(collectionName)}
                      className={"collection-wrap"}
                    >
                      {!isEmpty(collectionSliceData) && (
                        <CollectionByName
                          collectionSliceData={collectionSliceData}
                          handleRemove={handleRemove}
                        />
                      )}
                      <div className={"collection-details"}>
                        <h5 className={"collection-title"}>{collectionName}</h5>
                        <div className={"collection-image-wrap"}>
                          <Image
                            src={imageIcon}
                            alt="image-icon"
                            className={"collection-image"}
                          />
                          <span>
                            {
                              collectionDataByCollectionName[collectionName]
                                ?.length
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              }
            )
          ) : isLoadingCollection ? (
            Array.from({ length: 8 }, (_, i) => (
              <Col
                key={i}
                span={24}
                xxl={6}
                xl={6}
                lg={8}
                md={8}
                sm={12}
                className={"skeleton-main"}
              >
                <Skeleton.Input active className={"collection-skeleton"} />
              </Col>
            ))
          ) : (
            <NoDataFound classNames={"no-data-found-collection"} />
          )}
        </Row>
      </div>

      <Drawer
        placement="bottom"
        closable={false}
        onClose={onCloseCollectionDrawer}
        open={collectionDrawer?.isOpen}
        height={"94%"}
        className={"collection-drawer"}
      >
        <div className={"collection-drawer-header"}>
          <div
            className={"collection-drawer-back-btn"}
            onClick={onCloseCollectionDrawer}
          >
            <svg
              width="32"
              height="30"
              viewBox="0 0 32 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={"back-icon"}
            >
              <path
                d="M9.58031 23.5179L5.30348 9.00572L20.1872 5.50643M6.63407 9.7705L26.0608 20.9363"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p className={"back-btn-text"}>Back</p>
          </div>
          <h1 className={"collection-drawer-title"}>
            {collectionDrawer?.name}
          </h1>
          <ButtonComponent
            handleClick={() => handleRemoveCollection(collectionDrawer?.name)}
            btnText={"Delete Collection"}
            classNames={"collection-delete-btn"}
          />
        </div>
        <Modal
          open={deleteCollection?.isModelOpen}
          footer={false}
          closeIcon={false}
          onCancel={handleCancelCollection}
          className={"collection-delete-modal"}
        >
          <p className={"collection-delete-title"}>
            Are you sure want to delete this collection.
          </p>
          <p className={"collection-delete-name"}>
            Enter your collection name "
            <span className={"collection-name-title"}>
              {collectionDrawer?.name}
            </span>
            "
          </p>
          <InputComponent
            type="text"
            value={deleteCollection?.inputValue}
            handleChange={handleDeleteCollectionInputChange}
            placeholder="Enter Collection Name"
            classNames={"collection-delete-input"}
          />
          <div className={"save-btn-main"}>
            <ButtonComponent
              btnText={"Cancel"}
              handleClick={handleCancelCollection}
              classNames={"cancel-btn"}
            />
            <ButtonComponent
              btnText={"Delete"}
              disabled={!deleteCollection?.isConfirm}
              handleClick={() => handleSaveCollection(collectionDrawer?.name)}
              classNames={"delete-btn"}
            />
          </div>
        </Modal>
        <Row gutter={[30, 30]} className={"collection-drawer-row"}>
          <WebsiteCardContainer
            {...{
              data: collectionDataByCollectionName[
                collectionDrawer?.openCollection
              ],
              isCollection: true,
              token,
            }}
          />
        </Row>
      </Drawer>
    </div>
  );
};

export default CollectionView;
